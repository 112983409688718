import React from 'react'

import style from './Results.css'

const Results = ({ selected }) => {
  if (!selected)
    return null

  return (
    <div className={style.resultsContainer}>
      <div className={style.title}>
        {selected?.name}
      </div>
      <div className={style.subTitle}>
        Tests in this panel:
      </div>
      <ul className={style.list}>
        {selected?.results?.map((item, index) => (
          <li key={item.code + index} className={style.testListItem}>
            <span className={style.testListItemCode}>{item.code}</span>
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Results