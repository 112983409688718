const PASSWORD_STRENGTH_REG_EX = new RegExp(
    '(?=^.{8,}$)((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*[0-9])(?=.*[A-Z])(?=.*[^A-Za-z0-9]))^.*'
  );

export default function () {
  return (value) => {
    if (!PASSWORD_STRENGTH_REG_EX.test(value) || !value) {
      throw new Error(
        JSON.stringify({ id: 'auth.invalid_password' }),
      )
    }
  }
}
